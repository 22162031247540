<template>
  <div class="box has-max-width">
    <h3 class="title is-5 push-bottom-xxs">{{ $t('profile.account.interests.title') }}</h3>
    <p>{{ $t('profile.account.interests.helpText') }}</p>

    <form v-if="isPopulated" @submit.prevent="update">
      <FormField>
        <Checkbox
          v-model="interests.dog"
          :disabled="isLoading"
          :label="$t('profile.account.interests.dog')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.fishing"
          :disabled="isLoading"
          :label="$t('profile.account.interests.fishing')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.huntingTrips"
          :disabled="isLoading"
          :label="$t('profile.account.interests.huntingTrips')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.outdoors"
          :disabled="isLoading"
          :label="$t('profile.account.interests.outdoors')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.food"
          :disabled="isLoading"
          :label="$t('profile.account.interests.food')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.shooting"
          :disabled="isLoading"
          :label="$t('profile.account.interests.shooting')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.secondHand"
          :disabled="isLoading"
          :label="$t('profile.account.interests.secondHand')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.gadgets"
          :disabled="isLoading"
          :label="$t('profile.account.interests.gadgets')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.politics"
          :disabled="isLoading"
          :label="$t('profile.account.interests.politics')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.conservation"
          :disabled="isLoading"
          :label="$t('profile.account.interests.conservation')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="interests.education"
          :disabled="isLoading"
          :label="$t('profile.account.interests.education')">
        </Checkbox>
      </FormField>

      <div class="field is-grouped push-top-xl">
        <div class="flex align-center">
          <div class="control">
            <button :class="{ 'is-loading': isLoading }" class="button is-primary">
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isPopulated: false
    }
  },

  static () {
    return {
      interests: {}
    }
  },

  computed: {
    profile () {
      return this.$store.getters['profile/data']
    }
  },

  mounted () {
    this.populate()
  },

  methods: {
    populate () {
      if (this.profile) {
        Object.assign(this.interests, this.profile.interests)

        this.isPopulated = true
      }
    },

    async update () {
      this.isLoading = true

      try {
        await this.$store.dispatch('profile/update', {
          userId: this.profile.userId,
          interests: this.interests
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.isLoading = false
    }
  }
}
</script>
